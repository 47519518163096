import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function WkChipIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <svg width="44" height="17" viewBox="0 0 44 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="44" height="16" rx="8" fill="#F8D8A6" />
                <path
                    d="M12.6737 8.474C13.1944 8.56933 13.6124 8.75633 13.9277 9.035C14.2504 9.31367 14.4117 9.739 14.4117 10.311C14.4117 11.103 14.133 11.6677 13.5757 12.005C13.0184 12.335 12.2374 12.5 11.2327 12.5H8.94471V4.899H10.9357C13.033 4.899 14.0817 5.54433 14.0817 6.835C14.0817 7.28233 13.946 7.649 13.6747 7.935C13.4034 8.21367 13.0697 8.39333 12.6737 8.474ZM10.3967 5.966V8.023H11.2877C11.691 8.023 12.01 7.935 12.2447 7.759C12.4794 7.57567 12.5967 7.308 12.5967 6.956C12.5967 6.58933 12.472 6.33267 12.2227 6.186C11.9807 6.03933 11.5994 5.966 11.0787 5.966H10.3967ZM11.2437 11.411C11.779 11.411 12.186 11.3303 12.4647 11.169C12.7507 11.0077 12.8937 10.7033 12.8937 10.256C12.8937 9.82333 12.758 9.51167 12.4867 9.321C12.2227 9.13033 11.8597 9.035 11.3977 9.035H10.3967V11.411H11.2437ZM21.2092 4.899L21.0552 5.977H18.2502V8.1H20.6922V9.178H18.2502V11.411H21.2532V12.5H16.7982V4.899H21.2092ZM28.6227 4.899L28.4687 6.054H26.4887V12.5H25.0257V6.054H22.9797V4.899H28.6227ZM34.1572 12.5L33.6842 10.74H31.1652L30.6922 12.5H29.1852L31.5502 4.899H33.3432L35.6972 12.5H34.1572ZM31.4512 9.64H33.3872L32.4192 6.01L31.4512 9.64Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
}
