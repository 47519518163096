import { RefObject, useEffect, MouseEvent } from 'react';
import { useQuery, useUUIHistory } from '../../../hooks';
import { useAppSelector, useAppDispatch } from '../../../store';
import {
    getApplicationUrls,
    getAppResources,
    selectGlobalState,
    setPrintScript,
    setBookmarkPath,
    setTitle,
    setBreadCrumbState,
} from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import { clearLocationDataForOC } from '../../../utils/ocLocationService';
import { getAuthenticationProvider } from '../../authentication/AuthenticationProviderService';
import { determineRedirectUrl } from '../../common/screenHelpers';
import { IBaseOperation, TargetScreenType } from '../../common/types';
import { clGetQueue } from '../../contextLayerService/contextLayerService';
import { LegacyPageMetadata } from './useExternalUIState.hook';
import { IQuery } from './useExternalUISync.hook';

enum ExternalMessageType {
    WrongAccessToken = 'wrongAccessToken',
    OpenItemPage = 'openSpaItemPage',
    OpenListPage = 'openSpaListPage',
    OpenHybridPage = 'openHybridPage',
    OpenViewPage = 'openViewPage',
    UpdateHelpMenuForFacets = 'updateHelpMenuForFacets',
    UpdateBookMarkTitle = 'updateBookMarkTitle',
    SubscribeToLinksForContextMenu = 'subscribeToLinksForContextMenu',
    UpdatePageTitle = 'pageTitleUpdate',
}

interface ExternalMessageEvent {
    messageType: ExternalMessageType;
    payLoad?: {
        screenId?: number;
        entityInstanceId?: number;
        url?: string;
        screenNameId?: string;
        parameters?: Record<string, string>;
        pageScript?: string;
        bookmarkPath?: string;
        pageTitle?: string;
        breadcrumbTitle?: string;
    };
}

type UseExternalUICommunicationProps = {
    iframeRef: RefObject<HTMLIFrameElement>;
};

// eslint-disable-next-line max-lines-per-function
export const useExternalUICommunication = (props: UseExternalUICommunicationProps) => {
    const { iframeRef } = props;
    const applicationUrls = useAppSelector(getApplicationUrls);
    const { logoutUrl, uiContextRoot } = useAppSelector(getAppResources);
    const history = useUUIHistory();
    const { screenId } = useQuery<{ screenId: string }>();
    const dispatch = useAppDispatch();
    const logOut = async (): Promise<void> => {
        const queueManager = await clGetQueue();
        queueManager?.setQueuePaused();
        clearLocationDataForOC();
        const authProvider = getAuthenticationProvider();
        authProvider.signOut(logoutUrl, true);
    };

    const redirectToPage = ({ messageType, payLoad }: ExternalMessageEvent) => {
        let url: string | undefined;

        if (messageType === ExternalMessageType.OpenItemPage) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.Item,
                    targetScreenId: payLoad?.screenId,
                    targetScreenMode: 'Show',
                } as IBaseOperation,
                entityInstanceId: payLoad?.entityInstanceId,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenListPage) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.List,
                    targetScreenId: payLoad?.screenId,
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenHybridPage && payLoad?.url) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.Legacy,
                    targetScreenId: screenId,
                    targetScreenMode: 'Show',
                    url: encodeURIComponent(payLoad.url),
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenViewPage && payLoad?.screenNameId) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.View,
                    targetScreenName: payLoad.screenNameId,
                    targetScreenMode: 'Show',
                    url: payLoad.parameters && new URLSearchParams(payLoad.parameters).toString(),
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (url) {
            history.push(url);
        }
    };

    const { legacyContextRoot } = useAppSelector(getAppResources);
    const breadCrumbItems = useAppSelector(selectGlobalState('breadcrumbs'));
    const dispatchUrlChange = async (url?: string, bookmarkPath?: string, breadcrumbTitle?: string): Promise<void> => {
        if (!url) {
            return;
        }
        const metadataUrl = new URL(url);
        const { searchParams } = metadataUrl;
        searchParams.set('spaRequestType', 'pageMetadata');
        searchParams.set('_c', 'n');
        metadataUrl.search = searchParams.toString();
        const facetPageState = await getPageScriptFromUrl(metadataUrl.toString());
        const { pageScript, pageScriptUrl, breadcrumb } = facetPageState;
        if (pageScriptUrl) {
            if (pageScript && pageScript.printPage) {
                const facetPrintContent = pageScript.printPage.replace(/\s/g, '');
                if (facetPrintContent?.includes('functionprintPage(){returnfalse;};printPage();')) {
                    const summaryPageState = await getPageScriptFromUrl(`${legacyContextRoot}${pageScriptUrl}`);
                    pageScript.printPage = summaryPageState?.pageScript?.printPage;
                }
            }
        }
        if (!breadcrumb && breadcrumbTitle) {
            const bcIndex = breadCrumbItems?.findIndex((bc) => bc.title === breadcrumbTitle);
            dispatch(setBreadCrumbState({ bc: breadCrumbItems?.slice(0, bcIndex > -1 ? bcIndex + 1 : 0) }));
        } else if (breadcrumb?.isRequired && bookmarkPath) {
            breadcrumb.url = bookmarkPath;
            dispatch(setBreadCrumbState({ bc: breadCrumbItems.concat([breadcrumb]) }));
        }
        if (pageScript.printPage) {
            dispatch(setPrintScript(pageScript.printPage));
        }
        if (bookmarkPath) {
            dispatch(
                setBookmarkPath({
                    title: undefined,
                    bookmarkPath: bookmarkPath,
                }),
            );
        }
    };

    const getPageScriptFromUrl = async (url: string): Promise<LegacyPageMetadata> => {
        return await apiFetch<LegacyPageMetadata>(url, { skipTracking: true });
    };

    const attachEventHandlers = (): void => {
        const jQuery = iframeRef.current?.contentWindow?.['jQuery'] as (param: unknown) => Record<any, any> as IQuery;

        const handleContextMenu = (event: MouseEvent<HTMLAnchorElement>): void => {
            let contextMenuHref = event.currentTarget.getAttribute('contextmenuhref');
            if (contextMenuHref) {
                event.currentTarget.href = `${uiContextRoot}${String(getHrefForContextMenu(String(contextMenuHref)))}`;
            }
        };
        const handleMouseDown = (event: MouseEvent<HTMLAnchorElement>) => {
            let contextMenuHref = event.currentTarget.getAttribute('contextmenuhref');
            if (event.button === 1 && contextMenuHref) {
                event.currentTarget.href = `${uiContextRoot}${String(getHrefForContextMenu(String(contextMenuHref)))}`;
            }
        };

        jQuery?.('a[href*="#"]').off('contextmenu').on('contextmenu', handleContextMenu);
        jQuery?.('a[href*="#"]').off('mousedown').on('mousedown', handleMouseDown);
    };

    const onPostMessage = (event: MessageEvent<ExternalMessageEvent>) => {
        const { messageType, payLoad } = event.data;
        if (messageType === ExternalMessageType.UpdatePageTitle) {
            dispatch(
                setTitle({
                    title: payLoad?.pageTitle ?? '',
                }),
            );
        }

        if (messageType === ExternalMessageType.WrongAccessToken) {
            void logOut();
        }

        if (
            messageType === ExternalMessageType.OpenItemPage ||
            messageType === ExternalMessageType.OpenListPage ||
            messageType === ExternalMessageType.OpenHybridPage ||
            messageType === ExternalMessageType.OpenViewPage
        ) {
            redirectToPage({ messageType, payLoad });
        } else if (messageType === ExternalMessageType.UpdateHelpMenuForFacets && payLoad?.url) {
            void dispatchUrlChange(payLoad.url, payLoad.bookmarkPath, payLoad.breadcrumbTitle);
        } else if (messageType === ExternalMessageType.UpdateBookMarkTitle && payLoad?.pageTitle) {
            dispatch(
                setBookmarkPath({
                    title: payLoad.pageTitle,
                    bookmarkPath: undefined,
                }),
            );
        } else if (messageType === ExternalMessageType.SubscribeToLinksForContextMenu) {
            attachEventHandlers();
        }
    };

    useEffect(() => {
        window.addEventListener('message', onPostMessage, false);

        return () => {
            window.removeEventListener('message', onPostMessage, false);
        };
    }, [breadCrumbItems]);

    const send = <TMessage = unknown, TOptions extends WindowPostMessageOptions = WindowPostMessageOptions>(
        message: TMessage,
        options?: TOptions,
    ) => {
        const { targetOrigin = '*', ...rest } = options || {};
        iframeRef.current?.contentWindow?.postMessage(message, { targetOrigin, ...rest });
    };

    const getHrefForContextMenu = (url: string): string | undefined => {
        return determineRedirectUrl({
            operation: {
                targetScreenType: TargetScreenType.Legacy,
                targetScreenId: screenId,
                targetScreenMode: 'Show',
                url: encodeURIComponent(String(url)),
            } as IBaseOperation,
            applicationUrls,
        });
    };

    return {
        send,
        getHrefForContextMenu,
    };
};
