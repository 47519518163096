import { MouseEvent, RefObject, useEffect } from 'react';
import { useQuery, useUUIHistory } from '../../../hooks';
import { useAppSelector } from '../../../store';
import { getApplicationUrls, getAppResources } from '../../../store/slices';
import { determineRedirectUrl } from '../../common/screenHelpers';
import { IBaseOperation, TargetScreenType } from '../../common/types';
import { LoaderScope, useLoader } from '../../core/blockUi/useLoader.hook';
import { useExternalUICommunication } from './useExternalUIComunication.hook';

type UseExternalUISyncProps = {
    currentUrl?: string;
    iframeRef: RefObject<HTMLIFrameElement>;
};

export interface IQuery {
    (param: string, params?: object | string): Record<string, this>;
}

export const useExternalUISync = (props: UseExternalUISyncProps) => {
    const { currentUrl, iframeRef } = props;
    const history = useUUIHistory();
    const { legacyContextRoot, uiContextRoot } = useAppSelector(getAppResources);
    const applicationUrls = useAppSelector(getApplicationUrls);
    const { screenId } = useQuery<{ url: string; screenId: string }>();
    const { preventLoadingOnce } = useLoader({
        scope: LoaderScope.ExternalUI,
    });
    const { getHrefForContextMenu } = useExternalUICommunication({ iframeRef });

    useEffect(() => {
        const iframe = iframeRef?.current;

        if (!iframe) {
            return;
        }

        const getUrl = (encodedUrl: string) =>
            determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.Legacy,
                    targetScreenId: screenId,
                    targetScreenMode: 'Show',
                    url: encodedUrl,
                } as IBaseOperation,
                applicationUrls,
            });

        const syncUrlState = (newUrl?: string) => {
            if (!newUrl || newUrl === currentUrl) {
                return;
            }

            const legacyUrl = newUrl.replace(legacyContextRoot, '');
            const encodedUrl = encodeURIComponent(legacyUrl);
            const url = getUrl(encodedUrl);
            if (url) {
                window.history.replaceState(null, '', `${uiContextRoot}${url}`);
            }
        };

        const subscribeToFrameDomLinks = (jQuery?: IQuery) => {
            jQuery?.('a')
                .not('[href*="void(0)"]')
                .not('[href*="#"]')
                .not('[href*="javascript"]')
                .not('[href*="mailto"]')
                .one('click', (event: MouseEvent<HTMLAnchorElement>) => {
                    event.preventDefault();
                    const legacyUrl = event.currentTarget.href?.replace(legacyContextRoot, '');
                    const encodedUrl = encodeURIComponent(legacyUrl);

                    const url = getUrl(encodedUrl);

                    if (url) {
                        history.push(url);
                    }
                });

            jQuery?.('a[href*="mailto"]').on('click', () => {
                preventLoadingOnce();
            });

            jQuery?.('a[href*="#"]').on('contextmenu', (event: MouseEvent<HTMLAnchorElement>) => {
                let contextMenuHref = event.currentTarget.getAttribute('contextmenuhref');
                if (contextMenuHref) {
                    event.currentTarget.href = `${uiContextRoot}${String(
                        getHrefForContextMenu(String(contextMenuHref)),
                    )}`;
                }
            });
 
            jQuery?.('a[href*="#"]').on('mousedown', (event: MouseEvent<HTMLAnchorElement>) => {
                let contextMenuHref = event.currentTarget.getAttribute('contextmenuhref');
                if (event.button === 1 && contextMenuHref) {
                    event.currentTarget.href = `${uiContextRoot}${String(
                        getHrefForContextMenu(String(contextMenuHref)),
                    )}`;
                }
            });
        };

        const addDialogPopupsScroll = (jQuery?: IQuery) => {
            jQuery?.('body.ModalBody form').css('overflow', 'auto');
        };

        const loadHandler = () => {
            const jQuery = iframe.contentWindow?.['jQuery'] as (param: unknown) => Record<any, any>;
            const iFrameUrl = iframe.contentWindow?.location.href;

            syncUrlState(iFrameUrl);
            subscribeToFrameDomLinks(jQuery);
            addDialogPopupsScroll(jQuery);
        };

        iframe.addEventListener('load', loadHandler);

        return () => {
            iframe.removeEventListener('load', loadHandler);
        };
    }, [iframeRef, currentUrl]);
};
